<template>
  <div v-if="isModalOpen" class="modal">
    <div :class="step === 3 ? 'modal-content modal-content-margin' : 'modal-content '">
      <div class="close" @click="closeModal()">
        <img src="/img/ui-theme/close.svg" alt="Close">
      </div>
      <div class="modal-body">
        <div v-if="step == 1">
          <div class="d-flex flex-column text-center">
            <h1 class="h-text">COUNCIL NEEDS TO HEAR YOUR POSITIVE <br> SUPPORT FOR THIS LANDMARK PROJECT</h1>
            <p class="p-text">The Hervey Bay Esplanade Resort represents an <u class="font-italic">economic boost</u> for
              <br> Hervey Bay and the Fraser Coast region,
              bringing <u class="font-italic"> jobs</u>, <u class="font-italic">housing</u> and <br><u
                class="font-italic">tourism</u>.
            </p>
            <h3 class="sub-text">WHY DO YOU THINK THIS LANDMARK PROJECT IS RIGHT FOR<br> HERVEY BAY ?</h3>
            <textarea type="text" class="text-area" placeholder="Tell us..." :value="formData.description"
              @change="onChange($event)" name="description"></textarea>
            <button class="button" @click="changeStep(2)">Next Step</button>
          </div>
        </div>
        <div v-if="step == 2">
          <div class="d-flex flex-column text-center">
            <h1 class="h-text">COUNCIL NEEDS TO HEAR YOUR POSITIVE <br> SUPPORT FOR THIS LANDMARK PROJECT</h1>
            <p class="p-text">The Hervey Bay Esplanade Resort represents an <u class="font-italic">economic boost</u> for
              <br> Hervey Bay and the Fraser Coast region,
              bringing <u class="font-italic"> jobs</u>, <u class="font-italic">housing</u> and <br><u
                class="font-italic">tourism</u>.
            </p>
            <h3 class="heading">PROVIDE YOUR DETAILS</h3>
            <Label class="text-secondary required">Required <span>*</span></Label>
            <div class="d-flex input-flex">
              <input type="text" class="input-box" placeholder="First Name..." :value="formData.firstName"
                @change="onChange($event)" name="firstName" required /><span
                :class="errors.firstName ? 'text-danger input-box-span start-size' : 'input-box-span'">*</span>
            </div>
            <div class="d-flex input-flex">
              <input type="email" class="input-box" placeholder="Last Name..." :value="formData.lastName"
                @change="onChange($event)" name="lastName" required /><span
                :class="errors.lastName ? 'text-danger input-box-span start-size' : 'input-box-span'">*</span>
            </div>
            <div class="d-flex input-flex">
              <input type="email" class="input-box" placeholder="Email..." :value="formData.email"
                @change="onChange($event)" name="email" required /><span
                :class="errors.email ? 'text-danger input-box-span start-size' : 'input-box-span'">*</span>
            </div>
            <div class="d-flex input-flex">
              <input type="text" class="input-box" placeholder="Home or Business Address..." name="address" ref="address"
                id="address" required /><span
                :class="errors.address ? 'text-danger input-box-span start-size' : 'input-box-span'">*</span>
            </div>
            <button class="border-success button" @click="submitForm()">Submit Support</button>
            <p class="paragraph">Your data is safe and will not be used for any marketing purposes. <br>
              We appreciate your support.</p>
          </div>
        </div>

        <div v-if="step == 3">
          <div class="d-flex flex-column text-center">
            <h1 class="h-text">THANK YOU!</h1>
            <p>We appreciate you sharing your support.</p>
            <button class="button btn-done" @click="closeModal()">Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AWS from 'aws-sdk'

export default {
  props: [
    'open'
  ],
  data() {
    return {
      isModalOpen: true,
      step: 1,
      formData: {
        name: '',
        email: '',
        address: '',
        description: ''
      },
      errors: {
        name: false,
        email: false,
        address: false,
      }
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.$emit('updateOpenValue');
    },
    changeStep(stepCount) {
      this.step = stepCount
      if (stepCount == 2) {
        setTimeout(() => {
          let address = new google.maps.places.Autocomplete(
            this.$refs['address']
          )
          address.addListener('place_changed', () => {
            this.formData['address'] = address.getPlace().formatted_address
          })
        }, 1000);

      }
    },
    onChange(event) {
      this.formData[event.target.name] = event.target.value
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    submitForm() {
      self = this
      var email = this.formData.email
      var firstName = this.formData.firstName
      var lastName = this.formData.lastName
      var address = this.formData.address
      var comment = this.formData.description

      if (email == '' || this.isValidEmail(email)) {
        this.errors['email'] = true
      }
      if (firstName == '') {
        this.errors['firstName'] = true
      }
      if (firstName == '') {
        this.errors['lastName'] = true
      }
      if (address == '') {
        this.errors['address'] = true
      }
      if (email != '' && firstName != '' && lastName != '' && address != '' && this.isValidEmail(email)) {
        const feedbackFormParams = {
          location: 'hbhotel',
          data: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            email: email,
            address: address,
            comment: comment
          })
        }
        const originalString = `hbhotel&& ${new Date().toDateString()}`;
        const encodedString = btoa(originalString);
        fetch('https://production.metaluxe.com.au/api/v1/feedback_forms.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${encodedString}`
          },
          body: JSON.stringify({
            feedback_forms: feedbackFormParams
          })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            // Handle success, if needed
            self.changeStep(3)
          })
          .catch(error => {
            // Handle errors
            console.error('Error:', error);
          });
        self.errors = {
          name: false,
          email: false,
          address: false,
        }
      }

    }
  },
  setup() {

  },
  created() {
    console.log(this.isModalOpen)
  }
};
</script>

<style scoped>
/* Add your modal styles here */
h1,
h2,
h3,
p {
  color: #333;
}


.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.modal-content {
  background-color: #fefefe;
  margin: 20px auto;
  /* padding: 20px; */
  border: 1px solid #888;
  width: 65%;
}



.modal-content-margin {
  margin: 125px auto
}

.close {
  color: #aaa;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  padding-right: 20px;
  padding-top: 10px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.h-text {
  font-size: 24px;
  line-height: 34.68px;
  font-weight: 700;
}

.p-text {
  font-size: 19px;
  font-weight: 400;
  line-height: 30.24px;
  margin-top: 20px;

}

.sub-text {
  font-size: 18px;
  line-height: 26.01px;
  margin-top: 25px;
  font-weight: 700;
}

.text-area {
  height: 156px;
  width: 432px;
  margin: 25px auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.6px;
  padding: 12px;
}

.button {
  width: 322px;
  height: 71px;
  border-radius: 10px;
  border: 2px solid;
  margin: 39px auto;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.01px;
  background-color: #fff;
}

.input-box {
  background-color: #f3f3f3;
  height: 50px;
  width: 321px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  margin-top: 13px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.6px;
  color: black;
  padding: 0.375rem 0.75rem;
}

.input-box-span {
  color: black;
  font-size: xx-large;
  padding-left: 5px;
}

.input-flex {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 26.01px;
}

.required {
  text-align: center;
  margin-right: 260px;
}

.required span {
  color: black;
  font-size: xx-large;
}

.paragraph {
  font-size: 18px;
  font-weight: 400;
  line-height: 28.65px;
}

.btn-done {
  margin: 10px auto 120px auto;
}

.font-italic {
  font-style: italic;
}

.start-size {
  font-size: 50px;
}

::placeholder {
  color: #333333 !important;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #333333 !important;
}

@media only screen and (max-width: 710px) {
  .modal-content {
    width: 100%;
  }

  .text-area {
    width: 100%;
  }

  .required {
    width: 100%;
  }
}
</style>
