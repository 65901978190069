<style>
.back-arrow {
  padding-top: 18px;
  padding-left: 0px;
}

.brand-logo {
  padding-left: 90px;
}

@media (max-width: 600px) {
  .brand-logo {
    padding-left: 15px;
  }

  .back-arrow {
    position: absolute;
    left: 12px;
    padding-top: 3px;
  }

  .back-arrow img {
    width: 10px;
  }
}
</style>
<template>
  <div class="col-12 ps-3 pe-3 d-flex  justify-content-end theme-header" style="margin-top: 25px;">
    <router-link :to="{ name: 'DashboardHome' }" :class="backButtonEnable ? 'circle back-arrow' : 'd-none'">
      <img src="/img/ui-theme/back-arrow.png" alt="Fullscreen">
    </router-link>

    <div :class="backButtonEnable ? 'm-auto pt-3' : 'm-auto pt-3'">
      <div class="d-flex flex-column" v-if="showLogo">
        <img src="/img/ui-theme/sheratonhb.png" width="220" alt="">
      </div>
    </div>
    <!-- <div class="circle" type="button" @click="openModal">
      <img src="/img/ui-theme/chat-heart.png" alt="Fullscreen">
    </div> -->

    <ModalComponent v-if="open" @updateOpenValue="openModal"></ModalComponent>
  </div>
</template>

<script>
import global from '../global'
import { unslugify } from "unslugify";
import ModalComponent from './Modal.vue';

export default {
  name: 'TypeTopNav',
  props: [
    'heading',
    'backButton',
    'showLogo'
  ],
  data() {
    return {
      open: false,
      backButtonEnable: this.backButton === "true",
      showLogo: this.showLogo === "true"
    };
  },
  components: {
    ModalComponent,
  },
  setup() {
    const { theme } = global
    let displayHeading = ""

    return {
      theme, displayHeading
    }
  },
  created() {
    // this.displayHeading = unslugify(this.heading)
  },
  methods: {
    openModal() {
      this.open = !this.open;
    },
  },
};
</script>
