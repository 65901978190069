<template>
  <!-- Top navigation -->
  <DetailTopNav :heading="this.viewType" :subheading="this.assetType" />

  <!-- Range -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'range' || viewType == 'renders' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'floorplans' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'panoramas' || viewType == 'virtual-tour' || viewType == 'balcony-views' && this.assetTypesReady === true">
    <iframe :src="this.assetTypes[this.order].url" :title="this.assetTypes[this.order].title" width="100%" height="100%" allow="fullscreen"></iframe>
  </div>

  <!-- Brochures -->
  <div id="pdf-view-inline"></div>

  <OffCanvas />
</template>

<script>
import DetailTopNav from '@/components/DetailTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    DetailTopNav,
    OffCanvas
  },
  data() {
    return {
        assetTypes: null,
        assetOrder: null,
        currentAsset: null,
        folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
        currentIndex: null,
        assetNames: null,
        assetUrls: null,
        componentName: "AssetView",
        assetTypesReady: false,
        adobeApiPDFReady: false,
        adobeDCView: null,
    }
  },
  created() {
    if (this.assetType === 'Lobby') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.LobbyAsset.videos
        this.assetNames = this.theme.LobbyAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.LobbyAsset.floorplans
        this.assetNames = this.theme.LobbyAsset.floorplansName
        this.assetUrls = this.theme.LobbyAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.LobbyAsset.panoramas
        this.assetNames = this.theme.LobbyAsset.panoramasName
        this.assetUrls = this.theme.LobbyAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.LobbyAsset.balcony
        this.assetNames = this.theme.LobbyAsset.balconyName
        this.assetUrls = this.theme.LobbyAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.LobbyAsset.renders
        this.assetNames = this.theme.LobbyAsset.rendersName
        this.assetUrls = this.theme.LobbyAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.LobbyAsset.range
        this.assetNames = this.theme.LobbyAsset.rangeName
        this.assetUrls = this.theme.LobbyAsset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.LobbyAsset.pdf
        this.assetNames = this.theme.LobbyAsset.pdfName
        this.assetUrls = this.theme.LobbyAsset.pdfUrl
      }
    } else if (this.assetType === 'Lobby Bar') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.LobbyBarAsset.videos
        this.assetNames = this.theme.LobbyBarAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.LobbyBarAsset.floorplans
        this.assetNames = this.theme.LobbyBarAsset.floorplansName
        this.assetUrls = this.theme.LobbyBarAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.LobbyBarAsset.panoramas
        this.assetNames = this.theme.LobbyBarAsset.panoramasName
        this.assetUrls = this.theme.LobbyBarAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.LobbyBarAsset.balcony
        this.assetNames = this.theme.LobbyBarAsset.balconyName
        this.assetUrls = this.theme.LobbyBarAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.LobbyBarAsset.renders
        this.assetNames = this.theme.LobbyBarAsset.rendersName
        this.assetUrls = this.theme.LobbyBarAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.LobbyBarAsset.range
        this.assetNames = this.theme.LobbyBarAsset.rangeName
        this.assetUrls = this.theme.LobbyBarAsset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.LobbyBarAsset.pdf
        this.assetNames = this.theme.LobbyBarAsset.pdfName
        this.assetUrls = this.theme.LobbyBarAsset.pdfUrl
      }
    } else if (this.assetType === 'Rooftop Lounge') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.RooftopAsset.videos
        this.assetNames = this.theme.RooftopAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.RooftopAsset.floorplans
        this.assetNames = this.theme.RooftopAsset.floorplansName
        this.assetUrls = this.theme.RooftopAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.RooftopAsset.panoramas
        this.assetNames = this.theme.RooftopAsset.panoramasName
        this.assetUrls = this.theme.RooftopAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.RooftopAsset.balcony
        this.assetNames = this.theme.RooftopAsset.balconyName
        this.assetUrls = this.theme.RooftopAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.RooftopAsset.renders
        this.assetNames = this.theme.RooftopAsset.rendersName
        this.assetUrls = this.theme.RooftopAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.RooftopAsset.range
        this.assetNames = this.theme.RooftopAsset.rangeName
        this.assetUrls = this.theme.RooftopAsset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.RooftopAsset.pdf
        this.assetNames = this.theme.RooftopAsset.pdfName
        this.assetUrls = this.theme.RooftopAsset.pdfUrl
      }
    } else if (this.assetType === 'Master Plan Flythrough') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.MasterAsset.videos
        this.assetNames = this.theme.MasterAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.MasterAsset.floorplans
        this.assetNames = this.theme.MasterAsset.floorplansName
        this.assetUrls = this.theme.MasterAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.MasterAsset.panoramas
        this.assetNames = this.theme.MasterAsset.panoramasName
        this.assetUrls = this.theme.MasterAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.MasterAsset.balcony
        this.assetNames = this.theme.MasterAsset.balconyName
        this.assetUrls = this.theme.MasterAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.MasterAsset.renders
        this.assetNames = this.theme.MasterAsset.rendersName
        this.assetUrls = this.theme.MasterAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.MasterAsset.range
        this.assetNames = this.theme.MasterAsset.rangeName
        this.assetUrls = this.theme.MasterAsset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.MasterAsset.pdf
        this.assetNames = this.theme.MasterAsset.pdfName
        this.assetUrls = this.theme.MasterAsset.pdfUrl
      }
    } else if (this.assetType === 'Typical Hotel Room') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.HotelAsset.videos
        this.assetNames = this.theme.HotelAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.HotelAsset.floorplans
        this.assetNames = this.theme.HotelAsset.floorplansName
        this.assetUrls = this.theme.HotelAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.HotelAsset.panoramas
        this.assetNames = this.theme.HotelAsset.panoramasName
        this.assetUrls = this.theme.HotelAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.HotelAsset.balcony
        this.assetNames = this.theme.HotelAsset.balconyName
        this.assetUrls = this.theme.HotelAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.HotelAsset.renders
        this.assetNames = this.theme.HotelAsset.rendersName
        this.assetUrls = this.theme.HotelAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.HotelAsset.range
        this.assetNames = this.theme.HotelAsset.rangeName
        this.assetUrls = this.theme.HotelAsset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.HotelAsset.pdf
        this.assetNames = this.theme.HotelAsset.pdfName
        this.assetUrls = this.theme.HotelAsset.pdfUrl
      }
    } else if (this.assetType === 'Typical 2 Bed Apartment') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.ApartmentAsset.videos
        this.assetNames = this.theme.ApartmentAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.ApartmentAsset.floorplans
        this.assetNames = this.theme.ApartmentAsset.floorplansName
        this.assetUrls = this.theme.ApartmentAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.ApartmentAsset.panoramas
        this.assetNames = this.theme.ApartmentAsset.panoramasName
        this.assetUrls = this.theme.ApartmentAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.ApartmentAsset.balcony
        this.assetNames = this.theme.ApartmentAsset.balconyName
        this.assetUrls = this.theme.ApartmentAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.ApartmentAsset.renders
        this.assetNames = this.theme.ApartmentAsset.rendersName
        this.assetUrls = this.theme.ApartmentAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.ApartmentAsset.range
        this.assetNames = this.theme.ApartmentAsset.rangeName
        this.assetUrls = this.theme.ApartmentAsset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.ApartmentAsset.pdf
        this.assetNames = this.theme.ApartmentAsset.pdfName
        this.assetUrls = this.theme.ApartmentAsset.pdfUrl
      }
    } else if (this.assetType === 'Economics') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.EconAsset.videos
        this.assetNames = this.theme.EconAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.EconAsset.floorplans
        this.assetNames = this.theme.EconAsset.floorplansName
        this.assetUrls = this.theme.EconAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.EconAsset.panoramas
        this.assetNames = this.theme.EconAsset.panoramasName
        this.assetUrls = this.theme.EconAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.EconAsset.balcony
        this.assetNames = this.theme.EconAsset.balconyName
        this.assetUrls = this.theme.EconAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.EconAsset.renders
        this.assetNames = this.theme.EconAsset.rendersName
        this.assetUrls = this.theme.EconAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.EconAsset.range
        this.assetNames = this.theme.EconAsset.rangeName
        this.assetUrls = this.theme.EconAsset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.EconAsset.pdf
        this.assetNames = this.theme.EconAsset.pdfName
        this.assetUrls = this.theme.EconAsset.pdfUrl
      }
    }

    BunnyNetService.getRenders(this.folderStructure, this.assetType)
    .then(response => {
      let j = 0;
      // Remap array

      console.log(response.data);
      
      const menuItems = response.data.map((item) => {
        if (this.assetNames && this.assetNames.length >= 1){
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName,
            title: this.assetNames[j],
            url: this.assetUrls[j],
            id: j++
          };
        } else {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName,
            title: unslugify(item.ObjectName.split('.').slice(0,-1).join(".")).replace("hyphen","-"),
            url: [],
            id: j++
          };
        }
      });

      // Custom order array based on section and type
      if( this.assetOrder && this.assetOrder.length >= 1 ) {
        this.assetTypes = this.reorder(menuItems, this.assetOrder);
      } else {
        this.assetTypes = menuItems
      }

      this.assetTypesReady = true
      if (this.viewType == "pdf"){
        setTimeout( () => {
                if (this.adobeApiPDFReady == true){
                    console.log("It is ready")
                    console.log(this.assetUrls[this.order])
                    this.initialisePdf(this.assetUrls[this.order], this.assetNames[this.order])
                    // this.initialisePdf("https://developmentprog.com/pdf1.pdf", "Florian Brochure")
                }
            },
            "1000"
        )
      }
    })
  },
  methods: {
    // Reorder array based on config setup
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },
    initialisePdf: function(pdfLink, pdfName){
        this.adobeDCView.previewFile({
            content: {location: {url: pdfLink}},
            metaData: { fileName: pdfName },
        }, {embedMode: "IN_LINE", showDownloadPDF: false, showPrintPDF: false});
    },
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  },
  mounted() {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
            this.adobeApiPDFReady = true;
            console.log("Adobe created with adobe_dc_view_sdk.ready");
        });

        // Dynamically load Adobe SDK Viewer for this page
        const plugin = document.createElement("script");
        plugin.setAttribute(
            "src",
            "https://documentcloud.adobe.com/view-sdk/viewer.js"
        );
        plugin.async = true;
        document.head.appendChild(plugin);
    },
    watch: {
        adobeApiPDFReady(val) {
            if (val) {
                let view = new window.AdobeDC.View({
                    clientId: "d61f95f324b548daa6555fad9f3e09f3",
                    divId: "pdf-view-inline",
                });
                this.adobeDCView = Object.freeze(view);
                console.log("Adobe is mounted with Client ID");
            }
        },
    }
}
</script>