<template>
  <div class="col-12 d-flex align-items-end justify-content-between sticky-bottom theme-footer">
    <router-link :to="{ name: 'DashboardHome' }" class="ms-3 mb-2 btn-home circle d-none">
      <img src="/img/ui-theme/home-icon.svg" class="position-relative theme-footer-logo" style="height:20px" />
    </router-link>
    <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo" style="height:35px" />
  </div>
</template>

<script>
import global from '../global'

export default {
  name: 'TypeBotNav',
  setup() {
    const { theme } = global

    return {
      theme
    }
  }
};
</script>
