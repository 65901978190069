<template>
  <ViewTopNav :heading="this.viewType" :subheading="this.assetType" />

  <div class="position-absolute p-content">
    <div class="col-12 p-0 h-100">
      <div class="row g-0 h-100">

        <div class="col-12 col-md-6 col-lg-4 m-auto" v-for="asset in assetTypes" :key="asset.guid">
          <div class="row g-0">
            <div class="col-12 position-relative">

              <!-- Videos -->
              <router-link v-if="viewType === 'videos' && collectionId" :to="{
                name: 'VideoView',
                params: {
                  guid: asset.guid,
                  collectionId: asset.videoLibraryId
                }
              }" class="v-link">
                <img :src="theme.streamCdnUrl + '/' + asset.guid + '/' + asset.thumbnailFileName + '?width=700'"
                  class="img-fluid">
                <div class="render-name">
                  {{ asset.title }}
                </div>
              </router-link>

              <!-- Renders -->
              <router-link v-if="viewType === 'renders' || viewType === 'range'" :to="{
                name: 'AssetViewDetail',
                params: {
                  assetType: this.assetType,
                  viewType: this.viewType,
                  order: asset.id
                }
              }" class="r-link">
                <img
                  :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'"
                  class="img-fluid">
                <div class="render-name">
                  {{ asset.title }}
                </div>
              </router-link>

              <!-- Panoramas -->
              <router-link v-if="viewType === 'panoramas' || viewType === 'virtual-tour' || viewType === 'balcony-views'"
                :to="{
                  name: 'AssetViewDetail',
                  params: {
                    assetType: this.assetType,
                    viewType: this.viewType,
                    order: asset.id
                  }
                }" class="p-link">
                <img
                  :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'"
                  class="img-fluid">
                <div class="render-name">
                  {{ asset.title }}
                </div>
              </router-link>

              <!-- Floorplans -->
              <router-link v-if="viewType === 'floorplans'" :to="{
                name: 'AssetViewDetail',
                params: {
                  assetType: this.assetType,
                  viewType: this.viewType,
                  order: asset.id
                }
              }" class="f-link">
                <img
                  :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'"
                  class="img-fluid">
                <div class="render-name">
                  {{ asset.title }}
                </div>
              </router-link>

              <!-- PDF -->
              <router-link v-if="viewType === 'pdf'" :to="{
                name: 'AssetViewDetail',
                params: {
                  assetType: this.assetType,
                  viewType: this.viewType,
                  order: asset.id
                }
              }" class="f-link">
                <img
                  :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'"
                  class="img-fluid">
                <div class="render-name">
                  {{ asset.title }}
                </div>
              </router-link>
              <button class="btn-theme-menu mt-4 btn-support" @click="updateOpenModalComponent">
                <img src="/img/ui-theme/heart.png" class="mr-2">
                Show Support
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <ModalComponent v-if="openModal" @updateOpenValue="updateOpenModalComponent"></ModalComponent>
  </div>

  <ViewBotNav />
  <OffCanvas />
</template>

<script>
import ViewTopNav from '@/components/ViewTopNav.vue';
import ViewBotNav from '@/components/ViewBotNav.vue';
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global';
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import ModalComponent from '@/components/Modal.vue';

export default {
  name: "AssetView",
  props: [
    'assetType',
    'viewType',
    'lid',
    'collectionId'
  ],
  components: {
    ViewTopNav,
    ViewBotNav,
    OffCanvas,
    ModalComponent
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      videoStatus: false,
      assetNames: null,
      assetUrls: null,
      openModal: false,
    }
  },
  created() {
    if (this.assetType === 'Lobby') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.LobbyAsset.videos
        this.assetNames = this.theme.LobbyAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.LobbyAsset.floorplans
        this.assetNames = this.theme.LobbyAsset.floorplansName
        this.assetUrls = this.theme.LobbyAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.LobbyAsset.panoramas
        this.assetNames = this.theme.LobbyAsset.panoramasName
        this.assetUrls = this.theme.LobbyAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.LobbyAsset.balcony
        this.assetNames = this.theme.LobbyAsset.balconyName
        this.assetUrls = this.theme.LobbyAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.LobbyAsset.renders
        this.assetNames = this.theme.LobbyAsset.rendersName
        this.assetUrls = this.theme.LobbyAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.LobbyAsset.range
        this.assetNames = this.theme.LobbyAsset.rangeName
        this.assetUrls = this.theme.LobbyAsset.rangeUrl
      } else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.LobbyAsset.pdf
        this.assetNames = this.theme.LobbyAsset.pdfName
        this.assetUrls = this.theme.LobbyAsset.pdfUrl
      }
    } else if (this.assetType === 'Lobby Bar') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.LobbyBarAsset.videos
        this.assetNames = this.theme.LobbyBarAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.LobbyBarAsset.floorplans
        this.assetNames = this.theme.LobbyBarAsset.floorplansName
        this.assetUrls = this.theme.LobbyBarAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.LobbyBarAsset.panoramas
        this.assetNames = this.theme.LobbyBarAsset.panoramasName
        this.assetUrls = this.theme.LobbyBarAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.LobbyBarAsset.balcony
        this.assetNames = this.theme.LobbyBarAsset.balconyName
        this.assetUrls = this.theme.LobbyBarAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.LobbyBarAsset.renders
        this.assetNames = this.theme.LobbyBarAsset.rendersName
        this.assetUrls = this.theme.LobbyBarAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.LobbyBarAsset.range
        this.assetNames = this.theme.LobbyBarAsset.rangeName
        this.assetUrls = this.theme.LobbyBarAsset.rangeUrl
      } else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.LobbyBarAsset.pdf
        this.assetNames = this.theme.LobbyBarAsset.pdfName
        this.assetUrls = this.theme.LobbyBarAsset.pdfUrl
      }
    } else if (this.assetType === 'Rooftop Lounge') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.RooftopAsset.videos
        this.assetNames = this.theme.RooftopAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.RooftopAsset.floorplans
        this.assetNames = this.theme.RooftopAsset.floorplansName
        this.assetUrls = this.theme.RooftopAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.RooftopAsset.panoramas
        this.assetNames = this.theme.RooftopAsset.panoramasName
        this.assetUrls = this.theme.RooftopAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.RooftopAsset.balcony
        this.assetNames = this.theme.RooftopAsset.balconyName
        this.assetUrls = this.theme.RooftopAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.RooftopAsset.renders
        this.assetNames = this.theme.RooftopAsset.rendersName
        this.assetUrls = this.theme.RooftopAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.RooftopAsset.range
        this.assetNames = this.theme.RooftopAsset.rangeName
        this.assetUrls = this.theme.RooftopAsset.rangeUrl
      } else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.RooftopAsset.pdf
        this.assetNames = this.theme.RooftopAsset.pdfName
        this.assetUrls = this.theme.RooftopAsset.pdfUrl
      }
    } else if (this.assetType === 'Master Plan Flythrough') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.MasterAsset.videos
        this.assetNames = this.theme.MasterAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.MasterAsset.floorplans
        this.assetNames = this.theme.MasterAsset.floorplansName
        this.assetUrls = this.theme.MasterAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.MasterAsset.panoramas
        this.assetNames = this.theme.MasterAsset.panoramasName
        this.assetUrls = this.theme.MasterAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.MasterAsset.balcony
        this.assetNames = this.theme.MasterAsset.balconyName
        this.assetUrls = this.theme.MasterAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.MasterAsset.renders
        this.assetNames = this.theme.MasterAsset.rendersName
        this.assetUrls = this.theme.MasterAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.MasterAsset.range
        this.assetNames = this.theme.MasterAsset.rangeName
        this.assetUrls = this.theme.MasterAsset.rangeUrl
      } else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.MasterAsset.pdf
        this.assetNames = this.theme.MasterAsset.pdfName
        this.assetUrls = this.theme.MasterAsset.pdfUrl
      }
    } else if (this.assetType === 'Typical Hotel Room') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.HotelAsset.videos
        this.assetNames = this.theme.HotelAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.HotelAsset.floorplans
        this.assetNames = this.theme.HotelAsset.floorplansName
        this.assetUrls = this.theme.HotelAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.HotelAsset.panoramas
        this.assetNames = this.theme.HotelAsset.panoramasName
        this.assetUrls = this.theme.HotelAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.HotelAsset.balcony
        this.assetNames = this.theme.HotelAsset.balconyName
        this.assetUrls = this.theme.HotelAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.HotelAsset.renders
        this.assetNames = this.theme.HotelAsset.rendersName
        this.assetUrls = this.theme.HotelAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.HotelAsset.range
        this.assetNames = this.theme.HotelAsset.rangeName
        this.assetUrls = this.theme.HotelAsset.rangeUrl
      } else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.HotelAsset.pdf
        this.assetNames = this.theme.HotelAsset.pdfName
        this.assetUrls = this.theme.HotelAsset.pdfUrl
      }
    } else if (this.assetType === 'Typical 2 Bed Apartment') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.ApartmentAsset.videos
        this.assetNames = this.theme.ApartmentAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.ApartmentAsset.floorplans
        this.assetNames = this.theme.ApartmentAsset.floorplansName
        this.assetUrls = this.theme.ApartmentAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.ApartmentAsset.panoramas
        this.assetNames = this.theme.ApartmentAsset.panoramasName
        this.assetUrls = this.theme.ApartmentAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.ApartmentAsset.balcony
        this.assetNames = this.theme.ApartmentAsset.balconyName
        this.assetUrls = this.theme.ApartmentAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.ApartmentAsset.renders
        this.assetNames = this.theme.ApartmentAsset.rendersName
        this.assetUrls = this.theme.ApartmentAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.ApartmentAsset.range
        this.assetNames = this.theme.ApartmentAsset.rangeName
        this.assetUrls = this.theme.ApartmentAsset.rangeUrl
      } else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.ApartmentAsset.pdf
        this.assetNames = this.theme.ApartmentAsset.pdfName
        this.assetUrls = this.theme.ApartmentAsset.pdfUrl
      }
    } else if (this.assetType === 'Economics') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.EconAsset.videos
        this.assetNames = this.theme.EconAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.EconAsset.floorplans
        this.assetNames = this.theme.EconAsset.floorplansName
        this.assetUrls = this.theme.EconAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.EconAsset.panoramas
        this.assetNames = this.theme.EconAsset.panoramasName
        this.assetUrls = this.theme.EconAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.EconAsset.balcony
        this.assetNames = this.theme.EconAsset.balconyName
        this.assetUrls = this.theme.EconAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.EconAsset.renders
        this.assetNames = this.theme.EconAsset.rendersName
        this.assetUrls = this.theme.EconAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.EconAsset.range
        this.assetNames = this.theme.EconAsset.rangeName
        this.assetUrls = this.theme.EconAsset.rangeUrl
      } else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.EconAsset.pdf
        this.assetNames = this.theme.EconAsset.pdfName
        this.assetUrls = this.theme.EconAsset.pdfUrl
      }
    }



    // Video type
    if (this.viewType === 'videos') {
      BunnyNetService.getVideos(this.lid, this.collectionId)
        .then(response => {
          // Remap array (inject asset names)
          console.log(response)
          let j = 0;
          const menuItems = response.data.items.map((item) => {
            if (this.assetNames && this.assetNames.length >= 1) {
              return {
                guid: item.guid,
                videoLibraryId: item.videoLibraryId,
                thumbnailFileName: item.thumbnailFileName,
                ObjectName: item.ObjectName,
                title: this.assetNames[j],
                id: j++
              };
            } else {
              return {
                guid: item.guid,
                videoLibraryId: item.videoLibraryId,
                thumbnailFileName: item.thumbnailFileName,
                ObjectName: item.ObjectName,
                title: unslugify(item.title.split('.').slice(0, -1).join(".")).replace("hyphen", "-"),
                id: j++
              };
            }
          });

          // Custom order array
          if (this.assetOrder && this.assetOrder.length >= 1) {
            this.assetTypes = this.reorder(menuItems, this.assetOrder);
          } else {
            this.assetTypes = menuItems;
          }

          this.videoStatus = true
        })
        .catch(error => {
          console.log(error)
        })
      // Renders, Panoramas, Floorplans type
    } else {
      BunnyNetService.getRenders(this.folderStructure, this.assetType)
        .then(response => {
          let j = 0;
          // Remap array
          console.log(response)
          const menuItems = response.data.map((item) => {
            if (this.assetUrls && this.assetUrls.length >= 1) {
              return {
                Guid: item.Guid,
                ObjectName: unslugify(item.ObjectName),
                LinkName: item.ObjectName,
                Path: item.Path,
                IsDirectory: item.IsDirectory,
                StorageZoneName: item.StorageZoneName,
                title: this.assetNames[j],
                url: this.assetUrls[j],
                id: j++
              };
            } else {
              return {
                Guid: item.Guid,
                ObjectName: unslugify(item.ObjectName),
                LinkName: item.ObjectName,
                Path: item.Path,
                IsDirectory: item.IsDirectory,
                StorageZoneName: item.StorageZoneName,
                title: unslugify(item.ObjectName.split('.').slice(0, -1).join(".")).replace("hyphen", "-"),
                url: [],
                id: j++
              };
            }
          });

          // Custom order array
          if (this.assetOrder && this.assetOrder.length >= 1) {
            this.assetTypes = this.reorder(menuItems, this.assetOrder);
          } else {
            this.assetTypes = menuItems
          }
        })
    }
  },
  methods: {
    reorder: function (data_array, real_order) {
      var i, d = {}, result = [];
      for (i = 0; i < data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for (i = 0; i < real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },
    updateOpenModalComponent() {
      this.openModal = !this.openModal;
    },
  },
  setup() {
    const { theme } = global

    return {
      theme
    }
  }
}
</script>
