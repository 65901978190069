import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "",
    streamToken: "",
    videoLibraryId: "172581",
    streamCdnUrl: "https://vz-af6754c9-f03.b-cdn.net",
    streamApiKey: "e8a69c50-0997-4f2e-899a01f443a4-69d4-42ec",
    storageApiPassword: "712df60e-26c6-43f0-b19243e61a26-0858-42cf",
    cdnUrl: "https://sheratonhb.b-cdn.net",
    devFolder: "/sheratonhb",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",

    // top level menu
    assetsMenuOrder: [
        'Master Plan Flythrough',
        'Lobby',
        'Lobby Bar',
        'Rooftop Lounge',
        'Typical Hotel Room',
        'Typical 2 Bed Apartment',
        'Economics',

    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf'
    ],

    LobbyAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    LobbyBarAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    RooftopAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [0, 1],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    MasterAsset: {
        videos: ['Master Plan', 'Lobby & Bar', 'Restaurant Buffet', 'Hotel Suite'],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    HotelAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    ApartmentAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    EconAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };
